import { createStore } from "vuex";

export default createStore({
    state: {
        items: [
            // {
            //     id: '1',
            //     name: 'Novel Bunch',
            //     price: 455.00,
            //     oldPrice: 459.99,
            //     sale: false,
            //     image: require('../assets/images/shop/shop-1.jpg'),
            //     imageHover: require('../assets/images/shop/shop-1-1.jpg'),
            // },
            // {
            //     id: '2',
            //     name: 'Book Chicks',
            //     price: 541.00,
            //     oldPrice: 540.99,
            //     sale: true,
            //     image: require('../assets/images/shop/shop-2.jpg'),
            //     imageHover: require('../assets/images/shop/shop-2-1.jpg'),
            // },
            // {
            //     id: '3',
            //     name: 'Book Divas',
            //     price: 140.00,
            //     oldPrice: 149.99,
            //     sale: false,
            //     image: require('../assets/images/shop/shop-3.jpg'),
            //     imageHover: require('../assets/images/shop/shop-3-1.jpg'),
            // },
            // {
            //     id: '4',
            //     name: 'Book Smart',
            //     price: 180.00,
            //     oldPrice: 199.99,
            //     sale: false,
            //     image: require('../assets/images/shop/shop-4.jpg'),
            //     imageHover: require('../assets/images/shop/shop-4-1.jpg'),
            // },
            // {
            //     id: '5',
            //     name: 'Book Broads',
            //     price: 180.00,
            //     oldPrice: 199.99,
            //     sale: false,
            //     image: require('../assets/images/shop/shop-5.jpg'),
            //     imageHover: require('../assets/images/shop/shop-5-1.jpg'),
            // },
            // {
            //     id: '6',
            //     name: 'Page Turners',
            //     price: 200.00,
            //     oldPrice: 219.99,
            //     sale: false,
            //     image: require('../assets/images/shop/shop-6.jpg'),
            //     imageHover: require('../assets/images/shop/shop-6-1.jpg'),
            // },
        ],
        cart: []
    },
    getters: {
        total: state => {
            if(state.cart.length > 0) {
                return state.cart.map(item => item.totalPrice).reduce((total, amount) => total + amount);
            } else {
                return 0;
            }
        }
    },
    mutations: {
        addToCart(state, payload) {
            let addedItem = state.items.find(item => item.id === payload.id)

            let existItem = state.cart.find(item => item.id == payload.id);

            if(existItem){
                existItem.productQuantity++;
                existItem.totalPrice += addedItem.price;
            } else {
                return state.cart.push(payload);
            }
        },

        removeFromCart(state, item){
            let index = state.cart.indexOf(item);
            state.cart.splice(index,1);
        }
    },
    actions: {

    }
});