<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Privacy Policy" />
        <PrivacyPolicyContent />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import PrivacyPolicyContent from '../PrivacyPolicy/PrivacyPolicyContent'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        PrivacyPolicyContent,
        Footer,
    }
}
</script>