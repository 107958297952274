import { createWebHistory, createRouter } from "vue-router";

// All Pages
import Home from "../components/Pages/Home";
import TermsConditionsPage from "../components/Pages/TermsConditionsPage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
// import HomePageTwo from "../components/Pages/HomePageTwo";
// import HomePageThree from "../components/Pages/HomePageThree";
// import HomePageFour from "../components/Pages/HomePageFour";
// import HomePageFive from "../components/Pages/HomePageFive";
// import HomePageSix from "../components/Pages/HomePageSix";
// import HomePageSeven from "../components/Pages/HomePageSeven";
// import HomePageEight from "../components/Pages/HomePageEight";
// import HomePageNine from "../components/Pages/HomePageNine";
// import HomePageTen from "../components/Pages/HomePageTen";
// import HomeElevenPage from "../components/Pages/HomeElevenPage";
// import HomePageTwelve from "../components/Pages/HomePageTwelve";
// import AboutPageOne from "../components/Pages/AboutPageOne";
// import AboutPageTwo from "../components/Pages/AboutPageTwo";
// import TeamPageOne from "../components/Pages/TeamPageOne";
// import TeamPageTwo from "../components/Pages/TeamPageTwo";
// import FeaturesPage from "../components/Pages/FeaturesPage";
// import PricingPage from "../components/Pages/PricingPage";
// import PartnerPage from "../components/Pages/PartnerPage";
// import LoginPage from "../components/Pages/LoginPage";
// import SignupPage from "../components/Pages/SignupPage";
// import FaqPage from "../components/Pages/FaqPage";
// import ServicesPageOne from "../components/Pages/ServicesPageOne";
// import ServicesPageTwo from "../components/Pages/ServicesPageTwo";
// import ServicesPageThree from "../components/Pages/ServicesPageThree";
// import ServicesPageDetails from "../components/Pages/ServicesPageDetails";
// import ProjectsPageOne from "../components/Pages/ProjectsPageOne";
// import ProjectsPageTwo from "../components/Pages/ProjectsPageTwo";
// import ProjectsPageThree from "../components/Pages/ProjectsPageThree";
// import ProjectsPageFour from "../components/Pages/ProjectsPageFour";
// import ProjectsPageDetails from "../components/Pages/ProjectsPageDetails";
// import ShopPage from "../components/Pages/ShopPage";
// import ProductDetailsPage from "../components/Pages/ProductDetailsPage";
// import CartPage from "../components/Pages/CartPage";
// import CheckoutPage from "../components/Pages/CheckoutPage";
// import BlogPageOne from "../components/Pages/BlogPageOne";
// import BlogPageTwo from "../components/Pages/BlogPageTwo";
// import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
// import ContactPage from "../components/Pages/ContactPage";

const routes = [
  { path: "/", component: Home },
  { path: "/terms-conditions", component: TermsConditionsPage },
  { path: "/privacy-policy", component: PrivacyPolicyPage },
  // { path: "/home-two", component: HomePageTwo },
  // { path: "/home-three", component: HomePageThree },
  // { path: "/home-four", component: HomePageFour },
  // { path: "/home-five", component: HomePageFive },
  // { path: "/home-six", component: HomePageSix },
  // { path: "/home-seven", component: HomePageSeven },
  // { path: "/home-eight", component: HomePageEight },
  // { path: "/home-nine", component: HomePageNine },
  // { path: "/home-ten", component: HomePageTen },
  // { path: "/home-eleven", component: HomeElevenPage },
  // { path: "/home-twelve", component: HomePageTwelve },
  // { path: "/about-one", component: AboutPageOne },
  // { path: "/about-two", component: AboutPageTwo },
  // { path: "/team-one", component: TeamPageOne },
  // { path: "/team-two", component: TeamPageTwo },
  // { path: "/features", component: FeaturesPage },
  // { path: "/pricing", component: PricingPage },
  // { path: "/partner", component: PartnerPage },
  // { path: "/login", component: LoginPage },
  // { path: "/signup", component: SignupPage },
  // { path: "/faq", component: FaqPage },
  // { path: "/services-one", component: ServicesPageOne },
  // { path: "/services-two", component: ServicesPageTwo },
  // { path: "/services-three", component: ServicesPageThree },
  // { path: "/services-details", component: ServicesPageDetails },
  // { path: "/projects-one", component: ProjectsPageOne },
  // { path: "/projects-two", component: ProjectsPageTwo },
  // { path: "/projects-three", component: ProjectsPageThree },
  // { path: "/projects-four", component: ProjectsPageFour },
  // { path: "/projects-details", component: ProjectsPageDetails },
  // { path: "/shop", component: ShopPage },
  // { path: "/products-details", component: ProductDetailsPage },
  // { path: "/cart", component: CartPage },
  // { path: "/checkout", component: CheckoutPage },
  // { path: "/blog-one", component: BlogPageOne },
  // { path: "/blog-two", component: BlogPageTwo },
  // { path: "/blog-details", component: BlogDetailsPage },
  // { path: "/contact", component: ContactPage },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
