<template>
  <div>
    <div :class="['navbar-area', { 'is-sticky': isSticky }]">
      <div class="bionix-nav">
        <div class="container">
          <nav class="navbar navbar-expand-lg navbar-light">
            <router-link class="navbar-brand" to="/">
              <img
                src="../../assets/images/AbramsSGlogo2.png"
                height="60"
                width="246"
                alt="logo"
              />
            </router-link>
            <!-- 
            <div
              class="navbar-toggler"
              @click="active = !active"
              :aria-pressed="active ? 'true' : 'false'"
              v-bind:class="{ active: button_active_state }"
              v-on:click="button_active_state = !button_active_state"
            >
              <span class="icon-bar top-bar"></span>
              <span class="icon-bar middle-bar"></span>
              <span class="icon-bar bottom-bar"></span>
            </div> -->

            <!-- <div class="collapse navbar-collapse" :class="{ show: active }">
              <ul class="navbar-nav">
                <li class="nav-item"> -->
            <!-- <a href="#" class="nav-link">
                    Home <i class="fas fa-chevron-down"></i>
                  </a> -->
            <!-- <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/" class="nav-link" exact
                        >Agency Business</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-two" class="nav-link"
                        >Digital Marketing - 1</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-three" class="nav-link"
                        >Digital Marketing - 2</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-four" class="nav-link"
                        >Startup - 1</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-five" class="nav-link">
                        IT Solutions
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-six" class="nav-link">
                        Startup - 2
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-seven" class="nav-link">
                        Marketing Services
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-eight" class="nav-link">
                        AI & Machine Learning
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-nine" class="nav-link">
                        Creative Portfolio
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-ten" class="nav-link">
                        Crypto Trading
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-eleven" class="nav-link">
                        Cyber Security Services
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-twelve" class="nav-link">
                        AI & Big Data
                      </router-link>
                    </li>
                  </ul> -->
            <!-- </li> -->

            <!-- <li class="nav-item">
                  <a href="#" class="nav-link">
                    Pages <i class="fas fa-chevron-down"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/about-one" class="nav-link"
                        >About Us Style One</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/about-two" class="nav-link"
                        >About Us Style Two</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/team-one" class="nav-link"
                        >Team Style One</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/team-two" class="nav-link"
                        >Team Style Two</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/features" class="nav-link"
                        >Features</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/pricing" class="nav-link"
                        >Pricing</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/partner" class="nav-link"
                        >Partner</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/login" class="nav-link"
                        >Login</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/signup" class="nav-link"
                        >Signup</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/faq" class="nav-link">FAQ</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/terms-conditions" class="nav-link"
                        >Terms & Conditions</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/privacy-policy" class="nav-link"
                        >Privacy Policy</router-link
                      >
                    </li>
                  </ul>
                </li>

                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Services <i class="fas fa-chevron-down"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/services-one" class="nav-link"
                        >Services Style One</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/services-two" class="nav-link"
                        >Services Style Two</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/services-three" class="nav-link"
                        >Services Style Three</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/services-details" class="nav-link"
                        >Service Details</router-link
                      >
                    </li>
                  </ul>
                </li>

                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Projects <i class="fas fa-chevron-down"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/projects-one" class="nav-link"
                        >Projects Style One</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/projects-two" class="nav-link"
                        >Projects Style Two</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/projects-three" class="nav-link"
                        >Projects Style Three</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/projects-four" class="nav-link"
                        >Projects Style Four</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/projects-details" class="nav-link"
                        >Project Details</router-link
                      >
                    </li>
                  </ul>
                </li>

                <li class="nav-item">
                  <router-link to="/shop" class="nav-link">Shop</router-link>
                </li>

                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Blog <i class="fas fa-chevron-down"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/blog-one" class="nav-link"
                        >Blog Grid</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/blog-two" class="nav-link"
                        >Blog Right Sidebar</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/blog-details" class="nav-link"
                        >Blog Details</router-link
                      >
                    </li>
                  </ul>
                </li>

                <li class="nav-item">
                  <router-link to="/contact" class="nav-link"
                    >Contact</router-link
                  >
                </li> -->
            <!-- </ul>
            </div> -->

            <!-- <div class="others-options">
              <div class="cart-items">
                <router-link to="/cart">
                  <i class="fas fa-shopping-cart"></i>
                  <span>{{ shoppingCart.length }}</span>
                </router-link>
              </div>

              <div class="option-item">
                <div class="search-box" v-on:click="isSearchMethod(isSearch)">
                  <i class="search-btn flaticon-search"></i>
                </div>
              </div>

              <div class="search-overlay" v-if="isSearch">
                <div class="d-table">
                  <div class="d-table-cell">
                    <div class="search-overlay-layer"></div>
                    <div class="search-overlay-layer"></div>
                    <div class="search-overlay-layer"></div>

                    <div
                      class="search-overlay-close"
                      v-on:click="isSearchMethod(isSearch)"
                    >
                      <span class="search-overlay-close-line"></span>
                      <span class="search-overlay-close-line"></span>
                    </div>

                    <div class="search-overlay-form">
                      <form>
                        <input
                          type="text"
                          class="input-search"
                          placeholder="Search here..."
                        />
                        <button type="submit">
                          <i class="flaticon-search"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div class="burger-menu" v-on:click="isMenuMethod(isMenu)">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div> -->
          </nav>
        </div>
      </div>
    </div>

    <!-- <div class="sidebar-modal" v-if="isMenu">
      <div class="sidebar-modal-inner"> -->
    <!-- <button
          type="button"
          class="close-btn"
          v-on:click="isMenuMethod(isMenu)"
        >
          ×
        </button> -->
    <!-- <div class="sidebar-about-area">
          <div class="title">
            <h2>About Us</h2>
            <p>
              We believe brand interaction is key in communication. Real
              innovations and a positive customer experience are the heart of
              successful communication. No fake products and services. The
              customer is king, their lives and needs are the inspiration.
            </p>
          </div>
        </div> -->
    <!-- <div class="sidebar-instagram-feed">
          <h2>Instagram</h2>

          <ul>
            <li>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  src="../../assets/images/instagram/instagram-1.jpg"
                  alt="image"
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  src="../../assets/images/instagram/instagram-2.jpg"
                  alt="image"
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  src="../../assets/images/instagram/instagram-3.jpg"
                  alt="image"
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  src="../../assets/images/instagram/instagram-4.jpg"
                  alt="image"
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  src="../../assets/images/instagram/instagram-5.jpg"
                  alt="image"
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  src="../../assets/images/instagram/instagram-6.jpg"
                  alt="image"
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  src="../../assets/images/instagram/instagram-7.jpg"
                  alt="image"
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  src="../../assets/images/instagram/instagram-8.jpg"
                  alt="image"
                />
              </a>
            </li>
          </ul>
        </div> -->
    <!-- <div class="sidebar-contact-area">
          <div class="contact-info">
            <div class="contact-info-content">
              <h2>
                <a href="tel:+0881306298615">+088 130 629 8615</a>
                <span>OR</span>
                <a href="mailto:hello@bionix.com">hello@bionix.com</a>
              </h2>

              <ul class="social">
                <li>
                  <a href="https://twitter.com/" target="_blank">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/" target="_blank">
                    <i class="fab fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
    <!-- </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      isSticky: true,
      isSearch: false,
      isMenu: false,
      active: false,
      button_active_state: false,
    };
  },
  // mounted() {
  //   const that = this;
  //   window.addEventListener("scroll", () => {
  //     let scrollPos = window.scrollY;
  //     if (scrollPos >= 0) {
  //       that.isSticky = false;
  //     } else {
  //       that.isSticky = true;
  //     }
  //   });
  // },
  // computed: {
  //   shoppingCart() {
  //     return this.$store.state.cart;
  //   },
  // },
  // methods: {
  //   isSearchMethod(isSearch) {
  //     return (this.isSearch = !isSearch);
  //   },
  //   isMenuMethod(isMenu) {
  //     return (this.isMenu = !isMenu);
  //   },
  // },
};
</script>
