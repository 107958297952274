<template>
  <div class="services-area ptb-100">
    <div class="container">
      <div class="section-title">
        <span class="sub-title">What We Do</span>
        <h2>Popular Offerings</h2>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-digital-marketing"></i>
            </div>

            <h3>
              PCI Compliance
            </h3>

            <p>
              When you choose Abrams, you’re choosing a partner who will
              simplify the compliance process. From readiness assessments for
              those gearing up for an audit, to evaluating mature security and
              compliance programs – we can help.
            </p>
            <!-- <router-link to="/services-details" class="read-more-btn"
              >Read More <i class="flaticon-right-arrow"></i
            ></router-link> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-services-box">
            <div class="icon bg-00aeff">
              <i class="flaticon-research"></i>
            </div>

            <h3>
             Managed Services
            </h3>

            <p>
              Abrams offers a broad range of customizable products and services
              to keep your organization on track and on budget. Whether you
              simply need an on-demand trusted advisor or holistic managed
              services – we’ve got solutions.
            </p>
            <!-- <router-link to="/services-details" class="read-more-btn"
              >Read More <i class="flaticon-right-arrow"></i
            ></router-link> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-services-box">
            <div class="icon bg-f78acb">
              <i class="flaticon-analytics"></i>
            </div>

            <h3>
              Compliance Everyday
            </h3>

            <p>
              Our customers have complimentary access to a client version of
              ImpactIRM - a consolidated, cloud-based platform that provides
              collaborative, increased visibility into what you need to stay PCI
              ready all year long.
            </p>
            <!-- <router-link to="/services-details" class="read-more-btn"
              >Read More <i class="flaticon-right-arrow"></i
            ></router-link> -->
          </div>
        </div>

        <!-- <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-services-box">
            <div class="icon bg-cdf1d8">
              <i class="flaticon-analysis"></i>
            </div> -->

        <!-- <h3>
              <router-link to="/services-details">SEO Consultancy</router-link>
            </h3>

            <p>
              Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt.
            </p>
            <router-link to="/services-details" class="read-more-btn"
              >Read More <i class="flaticon-right-arrow"></i
            ></router-link>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-services-box">
            <div class="icon bg-c679e3">
              <i class="flaticon-mail"></i>
            </div>

            <h3>
              <router-link to="/services-details"
                >Competitor Analysis</router-link
              >
            </h3>

            <p>
              Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt.
            </p>
            <router-link to="/services-details" class="read-more-btn"
              >Read More <i class="flaticon-right-arrow"></i
            ></router-link>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-services-box">
            <div class="icon bg-eb6b3d">
              <i class="flaticon-seo"></i>
            </div>

            <h3>
              <router-link to="/services-details"
                >Social Media Marketing</router-link
              >
            </h3>

            <p>
              Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt.
            </p>
            <router-link to="/services-details" class="read-more-btn"
              >Read More <i class="flaticon-right-arrow"></i
            ></router-link> -->
        <!-- </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhatWeDo",
};
</script>
