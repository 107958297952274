<template>
  <div class="footer-area">
    <div class="container">
      <div class="row">
        <!-- <div class="col-lg-4 col-md-6 col-sm-6"> -->
        <div class="d-flex justify-content-center">
          <router-link to="/">
            <img
              src="../../assets/images/ASGwhite1.png"
              height="60"
              width="246"
              alt="image"
            />
          </router-link>
        </div>
        <!-- </div> -->
        <div class="d-flex justify-content-center">
          <!-- <p>Our success is measured by <b>your</b> Impact.</p> -->
        </div>
      </div>

      <div class="row mt-5">
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="single-footer-widget d-flex justify-content-center">
              <ul class="footer-contact-info">
                <li>
                  <i class="flaticon-phone-call"></i>
                  <span>Phone</span>
                  <a href="tel:4047194999">404-719-4999</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 col-sm-6" style="margin-left: 0px">
            <div class="single-footer-widget d-flex justify-content-center">
              <ul class="footer-contact-info">
                <li>
                  <i class="flaticon-email"></i>
                  <span>Email</span>
                  <a href="mailto:team@abrams360.com">team@abrams360.com</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 col-sm-12" style="margin-left: 0px">
            <div class="single-footer-widget d-flex justify-content-center">
              <ul class="footer-contact-info">
                <li>
                  <i class="flaticon-marker"></i>
                  <span>Location</span>
                  <a
                    href="https://www.google.com/maps/@51.5287718,-0.2416804,11z"
                    target="_blank"
                    >Atlanta, GA</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="single-footer-widget">
            <h3>Contact Info</h3>

            <ul class="footer-contact-info">
              <li>
                <i class="flaticon-phone-call"></i>
                <span>Mon to Fri : 10:00AM - 06:00PM</span>
                <a href="tel:1235421457852">+123 54214 578 52</a>
              </li>
              <li>
                <i class="flaticon-email"></i>
                <span>Do You Have a Question?</span>
                <a href="mailto:hello@bionix.com">hello@bionix.com</a>
              </li>
              <li>
                <i class="flaticon-social-media"></i>
                <span>Socials Network</span>

                <ul class="social">
                  <li>
                    <a href="https://twitter.com/" target="_blank"
                      ><i class="fab fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank"
                      ><i class="fab fa-facebook-f"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank"
                      ><i class="fab fa-linkedin"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/" target="_blank"
                      ><i class="fab fa-youtube"></i
                    ></a>
                  </li>
                </ul>
              </li>
            </ul>
          </div> -->

      <!-- 
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Quick Links</h3>

                        <ul class="footer-quick-links">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/about-one">About</router-link></li>
                            <li><router-link to="/blog-one">Blog</router-link></li>
                            <li><router-link to="/contact">Contact</router-link></li>
                            <li><router-link to="/services-one">Services</router-link></li>
                            <li><router-link to="/features">Features</router-link></li>
                            <li><router-link to="/contact">Support</router-link></li>
                            <li><router-link to="/shop">Shop</router-link></li>
                            <li><router-link to="/pricing">Pricing</router-link></li>
                            <li><router-link to="/projects-one">Projects</router-link></li>
                            <li><router-link to="/contact">Support</router-link></li>
                            <li><router-link to="/shop">Shop</router-link></li>
                        </ul>
                    </div>
                </div> -->

      <!-- <div
          class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
        >
          <div class="single-footer-widget pl-5">
            <h3>Instagram</h3>

            <ul class="footer-instagram-post">
              <li>
                <a href="https://www.instagram.com/" target="_blank">
                  <img
                    src="../../assets/images/instagram/instagram-1.jpg"
                    alt="image"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank">
                  <img
                    src="../../assets/images/instagram/instagram-2.jpg"
                    alt="image"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank">
                  <img
                    src="../../assets/images/instagram/instagram-3.jpg"
                    alt="image"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank">
                  <img
                    src="../../assets/images/instagram/instagram-4.jpg"
                    alt="image"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank">
                  <img
                    src="../../assets/images/instagram/instagram-5.jpg"
                    alt="image"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank">
                  <img
                    src="../../assets/images/instagram/instagram-6.jpg"
                    alt="image"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      <div class="copyright-area">
        <div class="row align-items-center">
          <div class="col-lg-6 col-sm-6 col-md-6">
            <p>
              &copy; {{ currentYear }} Abrams Security Group, LLC. All rights
              reserved
              <!-- <a href="https://envytheme.com/" target="_blank">EnvyTheme</a> -->
            </p>
          </div>

          <div class="col-lg-6 col-sm-6 col-md-6">
            <ul>
              <!-- <li>
                <router-link to="/terms-conditions"
                  >Terms & Conditions</router-link
                >
              </li> -->
              <li>
                <router-link to="/privacy-policy">Privacy Policy</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
