<template>
  <div class="hero-banner banner-bg1">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="hero-banner-content">
                <span style="font-size:150%" class="sub-title">Abrams Security Group</span>
                <h1>PCI Compliance & Managed Services</h1>
                <p>
                  Providing effective, practical solutions for organizations of
                  all sizes.
                </p>
                <!-- <div class="btn-box">
                  <router-link to="/contact" class="default-btn">
                    Get Started
                    <span></span>
                  </router-link>
                </div> -->
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div class="hero-banner-image">
                <!-- <img src="../../assets/images/banner/main.png" alt="image" /> -->
                <img src="../../assets/images/choose.png" alt="image" />
                />
                <!-- <img
                  src="../../assets/images/banner/banner-1.png"
                  alt="image"
                />
                <img
                  src="../../assets/images/banner/banner-2.png"
                  alt="image"
                /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="shape-img1">
      <img src="../../assets/images/shape/shape-1.png" alt="image" />
    </div>
    <div class="shape-img2">
      <img src="../../assets/images/shape/shape-2.svg" alt="image" />
    </div>
    <div class="shape-img3">
      <img src="../../assets/images/shape/shape-3.svg" alt="image" />
    </div>
    <div class="shape-img4">
      <img src="../../assets/images/shape/shape-4.png" alt="image" />
    </div>
    <div class="shape-img5">
      <img src="../../assets/images/shape/shape-5.png" alt="image" />
    </div>
    <div class="shape-img6">
      <img src="../../assets/images/shape/shape-6.png" alt="image" />
    </div>
    <div class="shape-img7">
      <img src="../../assets/images/shape/shape-7.png" alt="image" />
    </div>
    <div class="shape-img8">
      <img src="../../assets/images/shape/shape-8.png" alt="image" />
    </div>
    <div class="shape-img9">
      <img src="../../assets/images/shape/shape-9.png" alt="image" />
    </div>
    <div class="shape-img10">
      <img src="../../assets/images/shape/shape-10.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBannerTwo",
};
</script>
