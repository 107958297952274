<template>
  <div class="about-area pb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="about-image">
            <img src="../../assets/images/2girlscoding.jpg" alt="image" />
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="about-content">
            <span class="sub-title">Services</span>
            <h2>Exceeding Expectations</h2>
            <p>
              Abrams provides tool-agnostic, unbiased recommendations and
              strategies that compliment your existing plans and goals, not work
              against them. Our Services Team provides expert support in the
              following areas:
            </p>

            <ul class="features-list">
              <li>
                <span><i class="fas fa-check"></i>ServiceNow</span>
              </li>
              <li>
                <span><i class="fas fa-check"></i>Project Management</span>
              </li>
              <li>
                <span><i class="fas fa-check"></i>JIRA</span>
              </li>
              <li>
                <span><i class="fas fa-check"></i>ITIL</span>
              </li>
              <li>
                <span><i class="fas fa-check"></i>ImpactIRM</span>
              </li>
              <li>
                <span><i class="fas fa-check"></i>SDLC</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="about-inner-area">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="about-text">
                <h3>Our History</h3>
                <p>Established in 2013</p>

                <ul class="features-list">
                  <li><i class="flaticon-tick"></i>PCI Assessments</li>
                  <li>
                    <i class="flaticon-tick"></i>Managed Services & Consulting
                  </li>
                  <li>
                    <i class="flaticon-tick"></i>Large and Small Environments
                  </li>
                  <!-- <li><i class="flaticon-tick"></i> Branding it better!</li> -->
                </ul>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="about-text">
                <h3>Our Mission</h3>
                <p>Providing Solutions for Organizations of All Sizes</p>

                <ul class="features-list">
                  <li>
                    <i class="flaticon-tick"></i> Integrate with Your Team
                  </li>
                  <li><i class="flaticon-tick"></i> Add Immediate Value</li>
                  <li><i class="flaticon-tick"></i> Leave It Better</li>
                  <!-- <li><i class="flaticon-tick"></i> In real we trust</li> -->
                </ul>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
            >
              <div class="about-text">
                <h3>Our Work Ethic</h3>
                <p>We Prove Ourselves, Year Over Year</p>

                <ul class="features-list">
                  <li><i class="flaticon-tick"></i>Creative Thinkers</li>
                  <li><i class="flaticon-tick"></i>Passionate About Results</li>
                  <li>
                    <i class="flaticon-tick"></i>Collaborative and Efficient
                  </li>
                  <!-- <li><i class="flaticon-tick"></i> Your brand, promoted</li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="shape-img3">
      <img src="../../assets/images/shape/shape-3.svg" alt="image" />
    </div>
    <div class="shape-img2">
      <img src="../../assets/images/shape/shape-2.svg" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>
