<template>
  <div>
    <Navbar />
    <MainBanner />
    <WhatWeDo />
    <WhyChooseUs />
    <OurPeople />
    <AboutUs />
    <!-- <FunFacts /> -->
    <Footer />
    <!-- <WeAreDigitalExplorers /> -->
    <!-- <ProvideAwesomeService /> -->
    <!-- <TheKeyToYourMotivation /> -->
    <!-- <WeveDoneLotsOfWork /> -->
    <!-- <WeAlwaysTryToUnderstand />
        <MeetOurAwesomeTeam />
        <GetBetterSolution />
        <SomeLovelyFeedback />
        <ChooseYourBestPlan />
        <WePartnerWithCompanies />
        <OurLatestNews />
        <GetOnlyNewUpdateTwo /> -->
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import MainBanner from "../Common/MainBanner";
import WhatWeDo from "../Common/WhatWeDo";
import WhyChooseUs from "../Common/WhyChooseUs";
import OurPeople from "../Common/OurPeople";
import AboutUs from "../Common/AboutUs";
// import FunFacts from "../Common/FunFacts";
import Footer from "../Layout/Footer";
// import ProvideAwesomeService from "../Common/ProvideAwesomeService";
// import TheKeyToYourMotivation from "../Common/TheKeyToYourMotivation";

// import WeveDoneLotsOfWork from "../Common/WeveDoneLotsOfWork";
// import WeAlwaysTryToUnderstand from '../Common/WeAlwaysTryToUnderstand'
// import MeetOurAwesomeTeam from '../Common/MeetOurAwesomeTeam'
// import GetBetterSolution from '../Common/GetBetterSolution'
// import SomeLovelyFeedback from '../Common/SomeLovelyFeedback'
// import ChooseYourBestPlan from '../Common/ChooseYourBestPlan'
// import WePartnerWithCompanies from '../Common/WePartnerWithCompanies'
// import OurLatestNews from '../Common/OurLatestNews'
// import GetOnlyNewUpdateTwo from '../Common/GetOnlyNewUpdateTwo'

export default {
  components: {
    Navbar,
    MainBanner,
    WhatWeDo,
    WhyChooseUs,
    OurPeople,
    AboutUs,
    // FunFacts,
    Footer,
    // WeAreDigitalExplorers,
    // ProvideAwesomeService,
    // TheKeyToYourMotivation,

    // WeveDoneLotsOfWork,
    // WeAlwaysTryToUnderstand,
    // MeetOurAwesomeTeam,
    // GetBetterSolution,
    // SomeLovelyFeedback,
    // ChooseYourBestPlan,
    // WePartnerWithCompanies,
    // OurLatestNews,
    // GetOnlyNewUpdateTwo,
  },
};
</script>
