<template>
  <div class="featured-services-area ptb-100">
    <div class="container">
      <div class="section-title">
        <span class="sub-title">Our People</span>
        <h2>Working With Us</h2>
        <p>
          With decades of experience across multiple industries, we've learned
          that understanding our clients is the key to success.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-featured-box">
            <div class="icon">
              <i class="flaticon-analytics"></i>
            </div>

            <h3>Trusted</h3>
            <p>
              An approved
              <b>PCI Qualified Security Assessor (QSA) Company</b> since 2014,
              industry experts are seasoned at navigating the security product
              and technology landscape, and providing guidance on risk-aligned
              solutions.
            </p>

            <!-- <router-link to="/services-details" class="default-btn"
              >Read More <span></span
            ></router-link> -->
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-featured-box">
            <div class="icon color-facd60">
              <i class="flaticon-mail"></i>
            </div>

            <h3>Experienced</h3>
            <p>
              Our Team members hold numerous technical and professional
              certifications – a few of the more common include: CISSP, PCI QSA,
              HCISPP, CISA, CISM – along with several advanced technical
              degrees.
            </p>

            <!-- <router-link to="/services-details" class="default-btn"
              >Read More <span></span
            ></router-link> -->
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3"
        >
          <div class="single-featured-box">
            <div class="icon color-1ac0c6">
              <i class="flaticon-research"></i>
            </div>

            <h3>Practical</h3>
            <p>
              Across all industries, Abrams works to balance our recommended
              course of action with budget plans and enterprise goals. Our
              talented professionals are here to help align your target goals
              with your organization’s needs.
            </p>

            <!-- <router-link to="/services-details" class="default-btn"
              >Read More <span></span
            ></router-link> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurPeople",
};
</script>
