<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Terms & Conditions" />
        <TermsConditionsContent />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import TermsConditionsContent from '../TermsConditions/TermsConditionsContent'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        TermsConditionsContent,
        Footer,
    }
}
</script>