<template>
  <div class="why-choose-area ptb-100 bg-F4F7FC">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="why-choose-content">
            <span class="sub-title">Why Choose Us</span>
            <h2>Differentiators</h2>
            <p>
              We're fortunate to enjoy lasting business relationships, built by
              focusing on the success of those around us – our Team, our
              Partners and our Clients – and we measure our success not just by
              our growth, but by
              <i><b>yours</b></i
              >.
            </p>

            <div class="features-text">
              <h4><i class="flaticon-tick"></i>Our Unique Approach</h4>
              <p>
                By keeping our boutique approach to providing services, we can
                control the quality and consistency of our work, and remain
                agile in all aspects of our business.
              </p>
            </div>

            <div class="features-text">
              <h4><i class="flaticon-tick"></i>Discretion</h4>
              <p>
                Although we're very proud of the fact that our consultants have
                been in Fortune 100 companies for a decade, we value our
                clients' privacy as much as they do.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="why-choose-image">
            <img src="../../assets/images/main.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyChooseUs",
};
</script>
